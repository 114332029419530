<template>
  <div>
    <HeaderBanner
      :title="headerText.title"
      :small="headerText.small"
      :img="headerText.img"
    ></HeaderBanner>
    <LeftImg
      :title="div1.title"
      :small="div1.small"
      :text1="div1.text1"
      :text2="div1.text2"
      :text3="div1.text3"
      :img="div1.img"
    ></LeftImg>
    <RightImg
      :title="div2.title"
      :small="div2.small"
      :text1="div2.text1"
      :text2="div2.text2"
      :text3="div2.text3"
      :img="div2.img"
    ></RightImg>
    <LeftImg
      :title="div3.title"
      :small="div3.small"
      :text1="div3.text1"
      :text2="div3.text2"
      :text3="div3.text3"
      :img="div3.img"
    ></LeftImg>
    <FormEnd></FormEnd>
  </div>
</template>

<script>
import HeaderBanner from "../components/headerBanner.vue";
import LeftImg from "../components/leftImg.vue";
import RightImg from "../components/rightImg.vue";
export default {
  name: "email",
  components: { HeaderBanner, LeftImg, RightImg },
  data() {
    return {
      headerText: {
        title: "创变邮箱营销解决方案",
        small: "海量优质IP，精准用户标签，让邮箱推广更简单",
        img: require("../assets/imgs/email/banner.png"),
      },
      div1: {
        title: "AI文案+丰富模板，轻松打造营销素材",
        small: "内置专业模板，AI自动生成文案，轻松打造引人注目的营销邮件...",
        img: require("../assets/imgs/email/template.png"),
      },
      div2: {
        title: "海量用户+投递技术，高效触达目标客户",
        small: "海量优质企业用户，领先的智能化邮件投递引擎技术与专业的邮件投递策略，保障95%邮件到达率...",
        img: require("../assets/imgs/email/efficient.png"),
      },
      div3: {
        title: "精细的用户标签，智能定向投递",
        small: "通过搜集用户标签完善用户全景画像，精准定向投放，实现个性化营销效果...",
        img: require("../assets/imgs/email/tabs.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>